@import '@tfl/component-library/build/assets/scss/common.scss';

.tfl-b2c-container .intro {
  &.tfl-edit-intro {
    font-family: $johnston-medium;
    margin-top: 40px;
    margin-bottom: 40px;
    h2 {
      margin-bottom: 0;
      font-size: 26px;
    }
  }
}

.tfl-b2c-container {
  #emailVerificationControl_info_message {
    margin-bottom: 24px;
  }
}
