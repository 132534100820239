/* Please add your custom scss here */
@import '@tfl/component-library/build/assets/scss/common.scss';
@import './components/LinkButton';
@import '/src/assets/scss/icons.scss';

#app {
  height: 100%;
  @media all and (max-width: 480px) {
    .tfl-header__outer-wrapper {
      position: absolute;
    } 
  }
}

.tfl-b2c-container {
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 104px;
  padding: 0 24px;
  width: 100%;
  z-index: 0;
  @include box-shadow;

  @media all and (max-width: 768px) {
    margin-top: 88px;
  }

  @media all and (max-width: 480px) {
    box-shadow: none;
    height: calc(100% - 64px);
    margin-top: 64px;
    padding: 0;
    position: relative;
  }
}

.tfl-b2c-container {
  .tfl-alert {
    &__header {
      font-size: 20.25px;
      padding-bottom: 8px;
      padding-left: 48px;
      padding-right: 16px;
      padding-bottom: 8px;
      font-weight: 400;
      margin-block-end: 0;
    }
  }

  .error.pageLevel {
    p {
      margin-bottom: 0;
    }

    margin-bottom: 24px;
  }

  .entry {
    .entry-item {
      .error.itemLevel p {
        margin-bottom: 0;
      }

      margin-bottom: 24px;
    }

    .create {
      margin-bottom: 24px;
    }
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }
}

.tfl-b2c-container {
  .tfl-button,
  .tfl-link-button {
    font-family: $johnston-medium;
    margin-bottom: 24px;
  }

  .tfl-button--disabled {
    &:hover {
      background-color: $tfl-grey-90;
      border-color: $tfl-grey-90;
      color: $tfl-white;
      cursor: not-allowed;
    }
  }
}

.tfl-b2c-container {
  .tfl-text-input__input:focus {
    outline: none;
  }

  .tfl-text-input__input {
    &:focus,
    &:hover {
      padding-left: calc(8px - 1px);
    }

    &:disabled {
      border-color: $tfl-grey-70;
      cursor: not-allowed;
      padding-left: 8px;

      &:focus,
      &:hover {
        border: 1px solid $tfl-grey-70;
        padding-left: 8px;
      }
    }
  }

  .tfl-dropdown__container {
    padding-left: 8px;

    &:focus,
    &:hover {
      padding-left: calc(8px - 1px);
    }

    &:disabled {
      border-color: $tfl-grey-70;
      cursor: not-allowed;

      &:focus,
      &:hover {
        border: 1px solid $tfl-grey-70;
        padding-left: 8px;
      }
    }
  }

  .tfl-text-input {
    &__input {
      &--hide {
        @include icon-tick-circle-grey;
        background-position: 98% 7px;
        padding: 0 100px 0 16px;
      }

      &--success {
        @include icon-tick-circle;
        background-position: 98% 7px;
        padding: 0 100px 0 16px;
      }

      &.has-password-toggle {
        padding-right: 85px;

        &--with-validation-indicator {
          padding-right: 110px;
        }
      }

      &:not([disabled]) {
        &.has-password-toggle {
          &:hover,
          &:focus {
            padding-right: 85px;
          }

          &--with-validation-indicator {
            &:hover,
            &:focus {
              padding-right: 110px;
            }
          }
        }
      }
    }
  }

  .tfl-loading-spinner {
    @include loading-spinner;
  }

  .tfl-text-input__input--loading {
    @include loading-spinner($background-position: 98% 7px);
  }

  .tfl-button-submit--loading {
    @include loading-spinner-submit;
    color: transparent;
    cursor: not-allowed;

    &:hover,
    &:focus {
      color: transparent;
    }
  }

  .tfl-checkbox__input:focus + .tfl-checkbox__label:before {
    border: none;
    outline: 1px solid $tfl-black;
    outline-offset: 4px;
  }

  .tfl-checkbox__input + .tfl-checkbox__label:before {
    top: 10px;
  }
}

.tfl-b2c-container {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    list-style-type: none;
  }
}

.tfl-b2c-container {
  .verificationSuccessText {
    font-family: $johnston-light;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.tfl-b2c-container {
  .tfl-server-error {
    padding: 11px 44px;
    font-family: $johnston-medium;
  }
}

.tfl-b2c-container {
  .tfl-password-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 65px;
    cursor: pointer;
    font-family: $johnston-medium;
    position: absolute;
    top: 46px;
    right: 40px;
    z-index: 1;
    padding: 0 5px;

    &--no-hide {
      right: 12px;
      padding: 0px 5px;
    }

    &:hover {
      background-color: $tfl-grey-30;
      border-radius: 8px;
      padding: 0 5px;
    }

    &:focus {
      border-radius: 8px;
      outline: none;
      box-shadow: 0 0 0 1px $tfl-black;
      padding: 0 5px;
    }
  }

  .hidden-clip {
    clip: rect(1px, 1px, 1px, 1px);
    margin: 0;
    position: absolute;
  }
}

// Bootstrap classes
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 48px;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 575px) {
  .container-sm,
  .container,
  .container-fluid {
    --bs-gutter-x: 32px;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
  }
}

@media (min-width: 576px) {
  .container-sm,
  .container,
  .container-fluid {
    --bs-gutter-x: 32px;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
  }
}

@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1440px;
  }
}
