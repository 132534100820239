@import '@tfl/component-library/build/assets/scss/common.scss';
@import '/src/assets/scss/icons.scss';

.tfl-passcode-match {
  &--wrapper {
    display: flex;
    margin-bottom: 4px;
    width: 100%;
  }
  &-icon {
    height: 32px;
    width: 32px;
    margin-right: 8px;
    margin-left: 7px;
    background-position: center;
    &--default {
      @include icon-radiobutton-unchecked;
    }
    &--success {
      @include icon-tick-circle-small;
    }
    &--failure {
      @include icon-clear-search($tfl-danger-red);
    }
  }
  &--description {
    width: calc(100% - 32px);
    padding-top: 4px;
  }
}

.tfl-b2c-container {
  .attrEntry.tfl-text-input {
    position: relative;
  }
  .tfl-passcode-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 65px;
    cursor: pointer;
    position: absolute;
    font-family: $johnston-medium;
    margin-top: -38px;
    right: 40px;
    z-index: 1;
    padding: 0 5px;
    &:hover {
      background-color: $tfl-grey-30;
      border-radius: 8px;
      padding: 0 5px;
    }
    &:focus {
      border-radius: 8px;
      outline: none;
      box-shadow: 0 0 0 1px $tfl-black;
      padding: 0 5px;
    }
  }
  .hidden-clip {
    clip: rect(1px, 1px, 1px, 1px);
    margin: 0;
    position: absolute;
  }
}
