@mixin icon-tick-circle-small(
  $tick-fill: '#{$tfl-white}',
  $circle-fill: '#{$tfl-success-green}',
  $height: 24px,
  $width: 24px
) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='#{$height}' width='#{$width}' viewBox='0 0 32 32'%3E%3Cpath fill='none' d='M0 0h32v32H0z'/%3E%3Ccircle cx='12' cy='12' r='12' transform='translate(4 4)' fill='#{url-encode($circle-fill)}'/%3E%3Cpath d='M10.9 16.5l3.4 3.2 6.8-7.4' fill='none' stroke='#{url-encode($tick-fill)}' stroke-linejoin='round' stroke-width='2.4'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

@mixin icon-tick-circle-grey(
  $tick-fill: '#{$tfl-white}',
  $circle-fill: '#{$tfl-grey-30}',
  $height: 32px,
  $width: 32px
) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='#{$height}' width='#{$width}' viewBox='0 0 32 32'%3E%3Cpath fill='none' d='M0 0h32v32H0z'/%3E%3Ccircle cx='12' cy='12' r='12' transform='translate(4 4)' fill='#{url-encode($circle-fill)}'/%3E%3Cpath d='M10.9 16.5l3.4 3.2 6.8-7.4' fill='none' stroke='#{url-encode($tick-fill)}' stroke-linejoin='round' stroke-width='2.4'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

@mixin icon-link-out($colour: '#{$tfl-black}', $height: 18px, $width: 18px) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='#{$height}' width='#{$width}' viewBox='0 0 32 32'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0 0h32v32H0z'/%3E%3Cpath class='a' d='M7 7h18v18H7z'/%3E%3Cg stroke-width='2.4' fill='none'%3E%3Cpath stroke='#{url-encode($colour)}' stroke-miterlimit='10' d='M16.5 15.485L24.985 7'/%3E%3Cpath d='M19 7h6v6' stroke='#{url-encode($colour)}'/%3E%3C/g%3E%3Cpath d='M24 16v8H8V10h8' stroke-linejoin='round' stroke='#{url-encode($colour)}' stroke-width='2.4' fill='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

@mixin loading-spinner($background-position: center ) {
  background-position: $background-position;
  background-repeat: no-repeat;
  animation: hide-show 2s ease alternate infinite;
}

@keyframes hide-show {
  from {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDpub25lO30uYntmaWxsOiNmNTg5YTY7fS5je2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIvPjxjaXJjbGUgY2xhc3M9ImIiIGN4PSIxMiIgY3k9IjEyIiByPSIxMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNCA0KSIvPjxjaXJjbGUgY2xhc3M9ImMiIGN4PSIyIiBjeT0iMiIgcj0iMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTQgMTQpIi8+PC9zdmc+);
    background-repeat: no-repeat;
  }
  to {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDpub25lO30uYntmaWxsOiNmNTg5YTY7fS5je2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIvPjxjaXJjbGUgY2xhc3M9ImIiIGN4PSIxMiIgY3k9IjEyIiByPSIxMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNCA0KSIvPjxjaXJjbGUgY2xhc3M9ImMiIGN4PSIxMCIgY3k9IjEwIiByPSIxMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNiA2KSIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
  }
}

@mixin loading-spinner-submit($background-position: center) {
  background-position: $background-position;
  background-repeat: no-repeat;
  animation: hide-show-submit 2s ease alternate infinite;
}

@keyframes hide-show-submit {
  from {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmEgewogICAgICAgIGZpbGw6IG5vbmU7CiAgICAgIH0KCiAgICAgIC5iIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICB9CgogICAgICAuYyB7CiAgICAgICAgZmlsbDogYmx1ZTsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgLz4KICA8Y2lyY2xlIGNsYXNzPSJiIiBjeD0iMTIiIGN5PSIxMiIgcj0iMTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQgNCkiIC8+CiAgPGNpcmNsZSBjbGFzcz0iYyIgY3g9IjIiIGN5PSIyIiByPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNCAxNCkiIC8+Cjwvc3ZnPg==);
    background-repeat: no-repeat;
  }
  to {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmEgewogICAgICAgIGZpbGw6IG5vbmU7CiAgICAgIH0KCiAgICAgIC5iIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICB9CgogICAgICAuYyB7CiAgICAgICAgZmlsbDogYmx1ZTsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgLz4KICA8Y2lyY2xlIGNsYXNzPSJiIiBjeD0iMTIiIGN5PSIxMiIgcj0iMTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQgNCkiIC8+CiAgPGNpcmNsZSBjbGFzcz0iYyIgY3g9IjEwIiBjeT0iMTAiIHI9IjEwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2IDYpIiAvPgo8L3N2Zz4=);
    background-repeat: no-repeat;
  }
}