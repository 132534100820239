@import '@tfl/component-library/build/assets/scss/common.scss';

.tfl-subtext {
  font-family: $johnston-light;
}

.tfl-subheader {
  font-size: 23.5px;
  font-family: $johnston-medium;
}

.tfl-b2c-container {
  li.tfl-country-dropdown {
    margin-bottom: 40px;
  }

  .tfl-find-your-address-container.hiding-results {
    margin-bottom: 40px
  }

  .tfl-address-dropdown-container.show {
    margin-bottom: 40px
  }

  .tfl-address-dropdown-container.hide {
    margin-bottom: 24px
  }
}
