/* Please add your custom scss here */
@import '@tfl/component-library/build/assets/scss/common.scss';
@import '/src/assets/scss/icons.scss';

.tfl-b2c-container {
  .heading {
    font-family: $johnston-medium;
    margin-top: 40px;
    margin-bottom: 24px;
    h1 {
      font-size: 26px;
      margin-bottom: 0;
    }
  }
  .tfl-subtext {
    font-family: $johnston-light;
  }
}

.tfl-b2c-container {
  .tfl-listElements {
    margin: 0 0 40px 0;
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 24px;
    }
  }

  li.tfl-alert-summary__link {
    margin-bottom: 0;
  }

  .tfl-alert__content {
    padding: 0 16px 16px 48px;
  }

  .tfl-signin-div {
    margin-bottom: 24px;
  }

  .textInParagraph {
    margin-bottom: 24px;
  }

  li {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 40px;
    }
  }

  .tfl-alert-summary li {
    margin-bottom: 0;
  }

  #termsAndConditionsFirstParagraphText a,
  #privacyStatementSecondParagraphText a {
    &::after {
      content: '\00a0\00a0\00a0\00a0';
    }

    @include icon-link-out;
    background-position: center right;
  }

  #termsOfUseConsentChoice_label {
    font-family: $johnston-medium;
    margin-bottom: 16px;
    font-size: inherit;
  }

  #termsOfUseConsentChoice_AgreeToReceiveOffersFromTfLYes {
    margin-bottom: 22px;
  }

  #AgreeToReceiveOffersFromTfLYes_option,
  #AgreeToReceiveOffersFromTocYes_option {
    margin-bottom: 0;
  }

  #ukAddress_FirstParagraphText_PostcodeLookup,
  #ukAddress_FirstParagraphText_ManualAddressEntry {
    font-family: $johnston-light;
  }
}

.tfl-b2c-container {
  .tfl-password-match {
    margin-bottom: 24px;
    font-family: Johnston100-Light, 'Avenir Next', Helvetica, Arial, sans-serif;
    &--wrapper {
      display: flex;
      margin-bottom: 4px;
      width: 100%;
    }
    &-icon {
      height: 32px;
      width: 32px;
      margin-right: 8px;
      margin-left: 7px;
      background-position: center;
      &--default {
        @include icon-radiobutton-unchecked;
      }
      &--success {
        @include icon-tick-circle-small;
      }
      &--failure {
        @include icon-clear-search($tfl-danger-red);
      }
    }
    &--description {
      width: calc(100% - 32px);
      padding-top: 4px;
    }
  }
}

.tfl-b2c-container {
  .Password {
    position: relative;
    #newPassword .error.itemLevel {
      margin-bottom: 16px;
    }
  }
}

.tfl-b2c-container {
  .tfl-button.tfl-find-your-address {
    margin-bottom: 0;
  }
  .tfl-manual-address-link {
    margin-bottom: 40px;
  }
}
