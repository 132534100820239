@import '@tfl/component-library/build/assets/scss/common.scss';

.tfl-b2c-container {
  #securityDetails_FirstParagraphText {
    margin-bottom: 8px;
  }
  
  #securityDetails_ThirdParagraphText {
    margin-top: -24px;
  }

  #securityPasscode_Answer_label {
    margin-bottom: 0;
  }

  .tfl-create-passcode-title {
    margin-bottom: 0;

    .textInParagraph {
      font-family: $johnston-medium;
    }
  }

  .tfl-passcode-match {
    margin-top: 24px;
    font-family: Johnston100-Light, 'Avenir Next', Helvetica, Arial, sans-serif;
  }
}

.tfl-b2c-container {
  .tfl-security-description-span {
    font-family: $johnston-light;
    display: block;
    margin-bottom: 8px;
  }
}
