#simplemodal-container,
#verifying_blurb,
#simplemodal-overlay {
  position: absolute;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
#simplemodal-overlay {
  background: transparent;
}