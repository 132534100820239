@import '@tfl/component-library/build/assets/scss/common.scss';
@import '/src/assets/scss/icons.scss';

.tfl-b2c-container {
  .tfl-phone-last-four-digits {
    font-family: $johnston-medium;
  }

  .tfl-change-mobile-number {
    display: block;
    margin-bottom: 24px;
    width: fit-content;
  }
}

.tfl-b2c-container {
  .tfl-call-charges {
    &::after {
      content: '\00a0\00a0\00a0\00a0';
    }

    @include icon-link-out;
    background-position: center right;
  }
}

.tfl-b2c-container {
  .tfl-mfa-challenge {
    .verificationSuccessText {
      margin-top: 0;
    }
  }
}