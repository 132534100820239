/* Please add your custom scss here */
@import '@tfl/component-library/build/assets/scss/common.scss';

.tfl-b2c-container {
  .heading {
    font-family: $johnston-medium;
    margin-top: 40px;
    margin-bottom: 24px;
    font-size: 26px;
  }
}

.tfl-b2c-container {
  #forgotPasswordSpan {
    display: flex;
    margin-top: 16px;
    height: fit-content;
    width: fit-content;
  }

  .forgot-password-question-mark {
    font-family: $johnston-light;
  }

  .divider {
    display: none;
  }
}

.tfl-b2c-container {
  .entry-item.tfl-text-input {
    position: relative;
  }

  .tfl-signin-password-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 65px;
    cursor: pointer;
    font-family: $johnston-medium;
    position: absolute;
    top: 48px;
    right: 12px;
    z-index: 1;
    padding: 0 5px;

    &:hover {
      background-color: $tfl-grey-30;
      border-radius: 8px;
      padding: 0 5px;
    }

    &:focus {
      border-radius: 8px;
      outline: none;
      box-shadow: 0 0 0 1px $tfl-black;
      padding: 0 5px;
    }
  }

  .hidden-clip {
    clip: rect(1px, 1px, 1px, 1px);
    margin: 0;
    position: absolute;
  }
}

.tfl-b2c-container {
  .tfl-validation-error>p {
    font-family: $johnston-medium;
  }
}