@import '@tfl/component-library/build/assets/scss/common.scss';

.tfl-subheading {
  font-family: $johnston-light;
}

.tfl-market-prefs-header {
  margin-top: 10px;
  margin-bottom: 24px;
}

#attributeList {
  .tfl-checkbox__input + .tfl-checkbox__label {
    line-height: 1.4em;
  }
}