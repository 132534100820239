@import '@tfl/component-library/build/assets/scss/common.scss';

html {
  font-size: 18px;
}

body {
  margin: 0;
  padding-bottom: 24px;
  @media all and (max-width: 480px) {
    padding: 0;
  }
}
