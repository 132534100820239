/* Please add your custom scss here */
@import '@tfl/component-library/build/assets/scss/common.scss';

.tfl-b2c-container {
  .attrEntry.tfl-text-input {
    position: relative;
  }
  .tfl-current-passcode-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 65px;
    cursor: pointer;
    font-family: $johnston-medium;
    position: absolute;
    margin-top: -38px;
    right: 12px;
    z-index: 1;
    padding: 0 5px;
    &:hover {
      background-color: $tfl-grey-30;
      border-radius: 8px;
      padding: 0 5px;
    }
    &:focus {
      border-radius: 8px;
      outline: none;
      box-shadow: 0 0 0 1px $tfl-black;
      padding: 0 5px;
    }
  }
  .hidden-clip {
    clip: rect(1px, 1px, 1px, 1px);
    margin: 0;
    position: absolute;
  }
}
